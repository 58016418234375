@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Anvha';
  src: local('Anvha'), url(./assets/fonts/anvha/Anvha.otf) format('opentype');
}

body {
  margin: 0;;
  font-family: "Anvha", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}