/* HeaderLines.css */
.code-editor {
    display: flex;
    height: 100vh; /* Set the height of the div */
    font-family: 'Vazir', sans-serif; /* Apply the Google Font */
    overflow: auto; /* Add scroll if content overflows */
    background-color: #282c34; /* Background color similar to code editors */
    color: #abb2bf; /* Text color similar to code editors */
  }
  
  .line-numbers {
    padding: 10px;
    background-color: #1e1e1e; /* Darker background for line numbers */
    color: #858585; /* Lighter color for line numbers */
    text-align: right;
    user-select: none; /* Prevent text selection */
  }
  
  .line-number {
    line-height: 20px; /* Set the line height */
  }
  
  .code-lines {
    padding: 10px;
    flex-grow: 1;
  }
  
  .code-line {
    line-height: 20px; /* Set the line height */
  }